<template>
  <div class="app-container">
    <div class="page-title">
      <h2>{{ $t('report.reports') }}</h2>
    </div>
    <el-card v-if="checkRole(['Admin'])" class="box-card filter-card just-for-admin">
      <div slot="header" class="clearfix">
        <span>{{ $t('general.filter') }}</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="initSearch">{{ $t('general.init') }}</el-button>
      </div>
      <div class="filter-container">
        <el-radio v-model="activeList" label="actives" border @change="getSubReportsList('indexactive')">{{ $t('general.F_actives') }}</el-radio>
        <el-radio v-model="activeList" label="desactives" border @change="getSubReportsList('indexdesactive')">{{ $t('general.F_desactives') }}</el-radio>
        <el-radio v-model="activeList" label="disponibles" border @change="handleFilterReports">{{ $t('general.disponibles') }}</el-radio>
        <el-radio v-model="activeList" label="corbeille" class="danger-radio" border @change="getSubReportsList('indexinvisible')">{{ $t('general.corbeille') }}</el-radio>
      </div>
    </el-card>

    <el-card class="box-card">
      <div class="filter-container">
        <el-input v-model="query.keyword" clearable :placeholder="$t('general.keyword')" class="wd-200 mr-15 filter-item" @keyup.enter.native="handleFilterReports" />
        <el-select v-model="query.processing" clearable class="filter-item wd-200 mr-15 filter-item" :placeholder="$t('general.processing')" style="width: 100%;" @change="handleFilterReports">
          <el-option v-for="(processing, index) in processings" :key="index" :label="processing | uppercaseFirst" :value="processing" />
        </el-select>
        <el-button v-waves class="mr-15 filter-item" type="primary" icon="el-icon-search" @click="handleFilterReports">
          {{ $t('general.search') }}
        </el-button>
        <el-button v-waves :loading="downloading" class="filter-item" type="primary" icon="el-icon-download" @click="handleDownload">
          {{ $t('general.export') }}
        </el-button>
        <!--el-button v-if="checkPermission(['create report'])" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="handleCreateReport">
          {{ $t('general.add') }}
        </el-button-->
      </div>
      <el-table v-loading="loading" :data="list" border fit highlight-current-row style="width: 100%">
        <!--el-table-column align="center" label="ID" width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.id }}</span>
          </template>
        </el-table-column-->

        <el-table-column align="center" :label="$t('report.subject')" width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.subject }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('report.reasons')" min-width="300px">
          <template slot-scope="scope">
            <span>{{ scope.row.reasons.substring(0, 100) }} ...</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('user.user')" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.subscriber.name }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('report.reported')" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.subscriber_reported.name }}</span>
          </template>
        </el-table-column>

        <!--el-table-column v-if="activeList!='corbeille' && checkPermission(['activate report'])" align="center" :label="$t('general.status')" width="100">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
              :value="scope.row.is_active"
              :disabled="!scope.row.is_visible"
              @change="setReportStatus(scope.row.id,'active')"
            />
          </template>
        </el-table-column-->

        <el-table-column align="center" :label="$t('general.processing')" width="100">
          <template slot-scope="scope">
            <el-tag :type="getprocessingColor(scope.row.processing)">{{ scope.row.processing | uppercaseFirst }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column v-if=" checkRole(['Admin']) && (activeList=='corbeille' || query.keyword)" align="center" :label="$t('general.restaurer')" width="100">
          <template slot-scope="scope">
            <el-switch
              v-if="!scope.row.is_visible"
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_visible"
              @change="setReportStatus(scope.row.id,'visible')"
            />
          </template>
        </el-table-column>
        <el-table-column v-if="checkPermission(['edit report','hide report','delete report'])" align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="150">
          <template slot-scope="scope">
            <el-button v-if="scope.row.is_visible && checkPermission(['edit report'])" type="primary" size="small" icon="el-icon-view" style="margin-right:10px;" @click="handleEditReport(scope.row.id);" />
            <el-button v-if="checkPermission(['hide report','delete report'])" slot="reference" type="danger" size="small" icon="el-icon-delete" style="margin-right:10px;" @click="handleDeleteReport(scope.row.id, scope.row.is_visible, scope.row.subject);" />
          </template>
        </el-table-column>
      </el-table>

      <pagination v-if="total > 0" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getReportsList" />

      <!-- ======= REGION USER DIALOG ======= -->
      <el-drawer
        :title="$t('report.report')"
        :visible.sync="dialogCreateReport"
        :before-close="handleCloseReportCreateDraw"
        :loading="reportCreating"
        size="30%"
      >
        <div class="content-inputs" style="padding:30px">
          <div class="report-subject margin-top">
            <h3>{{ $t('report.subject') }}</h3>
            <p>
              {{ currentReport.subject }}
            </p>
          </div>
          <div class="report-reasons margin-top">
            <h3>{{ $t('report.reasons') }}</h3>
            <p class="reasons-text">
              {{ currentReport.reasons }}
            </p>
          </div>
          <el-divider />
          <!--=============== USER CARD ==============-->
          <subscription-card :subscription="currentReport.subscriber" @sendmessage="sendmessage($event, currentReport.subscriber.user_id, currentReport.subscriber.name)" />
          <!--=============== END USER CARD ==============-->
          <!--=============== REPORTED USER CARD ==============-->
          <subscription-card :cardclass="'subscriber_reported-card'" :subscription="currentReport.subscriber_reported" @sendmessage="sendmessage($event, currentReport.subscriber_reported.user_id, currentReport.subscriber_reported.name)" />
          <!--=============== END REPORTED USER CARD ==============-->
          <el-divider />
          <el-card class="box-card">
            <el-form ref="reportForm" :rules="rules" :model="currentReport" label-position="left">
              <el-form-item :label="$t('general.processing')" prop="processing">
                <el-radio-group v-model="currentReport.processing">
                  <el-radio
                    v-for="(processing, idex) in processings"
                    :key="idex"
                    :label="processing"
                    :class="'radio-'+getprocessingColor(processing)"
                  > {{ processing | uppercaseFirst }} </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
            <span>
              <div style="text-align:right;">
                <el-button plain type="danger" @click="dialogCreateReport=false">
                  {{ $t('general.close') }}
                </el-button>
                <el-button type="primary" :loading="reportCreating" @click="editing ? updateReport() : createReport()">
                  {{ reportCreating ? $t('general.saving') : $t('general.save') }}
                </el-button>
              </div>
            </span>
          </el-card>
        </div>
      </el-drawer>

      <!--el-dialog
        :title="editing ? $t('report.edit') + ' ' + currentReport.name : $t('report.AddReport')"
        :visible.sync="dialogCreateReport"
        :before-close="handleCloseReportCreateDraw"
        :loading="reportCreating"
        width="70%"
      >
        <div class="content-inputs">
          <el-form ref="reportForm" :rules="rules" :model="currentReport" label-position="left">
            <el-form-item :label="$t('general.processing')" prop="processing">
              <el-select v-model="currentReport.processing" class="filter-item" :placeholder="$t('general.processing')" style="width: 100%;" prop="processing">
                <el-option
                  v-for="(processing, idex) in processings"
                  :key="idex"
                  :label="processing | uppercaseFirst"
                  :value="processing"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('report.subject')" prop="subject">
              <el-input v-model="currentReport.subject" />
            </el-form-item>
            <el-form-item :label="$t('report.reasons')" prop="reasons">
              <el-input v-model="currentReport.reasons" type="textarea" />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="danger" @click="dialogCreateReport=false">
              {{ $t('permission.cancel') }}
            </el-button>
            <el-button type="primary" :loading="reportCreating" @click="editing ? updateReport() : createReport()">
              {{ reportCreating ? $t('general.saving') : $t('general.save') }}
            </el-button>
          </div>
        </span>
      </el-dialog-->

      <!-- ======= REGION USER DIALOG ======= -->
      <el-dialog
        :title="$t('message.sendTo') + selectedUser.name"
        :visible.sync="sendsms"
        width="40%"
      >
        <send-message :id="selectedUser.id" :canals="[canal]" @close="sendsms=false" />
      </el-dialog>

    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
import subscriptionCard from '../subscriptions/components/SubscriptionCard';
import SendMessage from '../components/SendMessage';
const reportResource = new Resource('reports');
const subscriptionResource = new Resource('subscriptions');
export default {
  name: 'ReportsList',
  components: { Pagination, subscriptionCard, SendMessage },
  directives: { waves, permission, role },
  data() {
    return {
      selectedUser: {
        id: '',
        name: '',
      },
      sendsms: false,
      canal: '',
      list: null,
      total: 0,
      loading: false,
      downloading: false,
      reportCreating: false,
      editing: false,
      activeList: 'disponibles',
      processings: this.$processingStatus,
      query: {
        page: 1,
        limit: this.$limit,
        keyword: '',
        processing: 'waiting',
      },
      // currentReport: {},
      currentReport: {
        id: '',
        subject: '',
        reasons: '',
        subscriber: {
          id: 1,
          name: '',
          avatar: '',
        },
        subscriber_reported: {
          id: 1,
          name: '',
          avatar: '',
        },
      },

      dialogCreateReport: false,
      currentReportId: 0,
      rules: {
        subject: [{ required: true, message: this.$t('report.SubjectRequired'), trigger: 'blur' }],
        reasons: [{ required: true, message: this.$t('report.BodyRequired'), trigger: 'blur' }],
      },
    };
  },
  computed: {
  },
  created() {
    this.resetNewReport();
    this.getReportsList();
  },
  methods: {
    checkPermission,
    checkRole,
    sendmessage(canal, id, name){
      this.selectedUser.id = id;
      this.selectedUser.name = name;
      this.canal = canal;
      this.sendsms = true;
    },
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    handleCloseReportCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    cancelReportForm() {
      this.$refs.ReportCreationDrawer.closeDrawer();
    },
    getprocessingColor(processing){
      var color = 'primary';
      if (processing === 'waiting'){
        color = 'warning';
      } else if (processing === 'finished'){
        color = 'success';
      } else if (processing === 'canceled'){
        color = 'info';
      }
      return color;
    },
    initSearch(){
      this.query = {
        page: 1,
        limit: this.$limit,
        keyword: '',
        processing: 'waiting',
      };
      this.activeList = 'disponibles';
      this.handleFilterReports();
    },
    async getReportsList() {
      this.loading = true;
      const { limit, page } = this.query;
      const { data, meta } = await reportResource.list(this.query);
      this.list = data;
      this.list.forEach((element, index) => {
        element['index'] = (page - 1) * limit + index + 1;
      });
      this.total = meta.total;
      this.loading = false;
    },
    async getSubReportsList(subUrl) {
      this.loading = true;
      const { data } = await reportResource.sublist('/' + subUrl, this.query);
      this.list = data;
      this.loading = false;
    },
    handleFilterReports() {
      this.query.page = 1;
      this.getReportsList();
    },
    handleCreateReport() {
      this.editing = false;
      this.resetNewReport();
      this.dialogCreateReport = true;
      this.$nextTick(() => {
        this.$refs['reportForm'].clearValidate();
      });
    },
    handleEditReport(id){
      this.editing = true;
      this.resetNewReport();
      this.currentReport = this.list.find(abonne => abonne.id === id);
      this.dialogCreateReport = true;
      this.$nextTick(() => {
        this.$refs['reportForm'].clearValidate();
      });
    },
    async setReportStatus(id, type){
      const data = await reportResource.get('toogle_' + type + '/' + id);
      if (data.success){
        this.$message({
          type: 'success',
          message: this.$t('general.statusUpdated'),
        });
        this.initSearch();
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
      }
    },
    async setSubscriptionStatus(id, type){
      const data = await subscriptionResource.get('toogle_' + type + '/' + id);
      if (data.success){
        this.$notify({
          title: this.$t('general.success'),
          message: this.$t('general.statusUpdated'),
          type: 'success',
        });
        this.initSearch();
      } else {
        this.$notify.error({
          title: this.$t('general.error'),
          message: this.$t('general.statusUpdateError'),
        });
      }
    },
    handleDeleteReport(id, visible, name) {
      this.$confirm(this.$t('report.deleteWarn') + ' ' + name + '. ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        if (!visible && (checkRole(['Admin']) || checkPermission(['delete report']))) {
          this.$swal({
            title: this.$t('general.SureQuestion'),
            text: this.$t('general.irrversibleMessage'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('general.ouiSupprimer'),
          }).then((result) => {
            if (result.isConfirmed) {
              reportResource.destroy(id).then(response => {
                this.$message({
                  type: 'success',
                  message: this.$t('report.suppressionEffectue'),
                });
                this.initSearch();
              }).catch(error => {
                console.log(error);
              });
            }
          });
        } else {
          this.setReportStatus(id, 'visible');
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('general.suppressionAnnule'),
        });
      });
    },
    createReport() {
      this.$refs['reportForm'].validate((valid) => {
        if (valid) {
          this.currentReport.roles = [this.currentReport.role];
          this.reportCreating = true;
          reportResource
            .store(this.currentReport)
            .then(async(response) => {
              this.$message({
                message: this.$t('report.NewReport') + ' ' + this.currentReport.subject + ' ' + this.$t('report.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.currentReportId = response.data.id;
              this.resetNewReport();
              this.dialogCreateReport = false;
              this.handleFilterReports();
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.reportCreating = false;
            });
        } else {
          this.$message({
            type: 'danger',
            message: this.$t('general.invalidForm'),
          });
          return false;
        }
      });
    },
    updateReport() {
      this.$refs['reportForm'].validate((valid) => {
        if (valid) {
          this.reportCreating = true;
          reportResource.update(this.currentReport.id, this.currentReport).then(response => {
            this.resetNewReport();
            this.handleFilterReports();
            this.$notify({
              title: this.$t('general.success'),
              message: this.$t('report.isupdatedSuccessfully'),
              type: 'success',
            });
            this.dialogCreateReport = false;
            this.editing = false;
          })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.reportCreating = false;
            });
        } else {
          this.$message({
            type: 'danger',
            message: this.$t('general.invalidForm'),
          });
          return false;
        }
      });
    },
    resetNewReport() {
      this.currentReport = {
        id: '',
        subject: '',
        reasons: '',
        subscriber: {
          id: 1,
          name: '',
          avatar: '',
        },
        subscriber_reported: {
          id: 1,
          name: '',
          avatar: '',
        },
      };
    },
    handleDownload() {
      this.downloading = true;
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['ID', this.$t('report.subject'), this.$t('report.reasons'), this.$t('report.processing')];
        const filterVal = ['id', 'subject', 'reasons', 'processing'];
        const data = this.formatJson(filterVal, this.list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'Reports list GIFTOLIFE_' + moment().format('DD-MM-YYYY_hh-mm'),
        });
        this.downloading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}

</style>
