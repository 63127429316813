<template>
  <div class="send-message-form">
    <!-- ======= USER MESSAGE DIALOG ======= -->
    <div class="content-inputs">
      <el-checkbox-group v-model="canals">
        <el-checkbox label="Mail" border />
        <el-checkbox label="Push Notification" border />
        <el-checkbox v-show="!general" label="Internal Message" border />
      </el-checkbox-group>
      <el-form ref="messageForm" :rules="rules" :model="currentMessage" label-position="left">
        <el-form-item :label="$t('message.subject')" prop="subject">
          <el-input v-model="currentMessage.subject" />
        </el-form-item>
        <el-form-item :label="$t('message.message')" prop="body">
          <el-input v-model="currentMessage.body" style="min-height:150px" type="textarea" />
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <div style="text-align:right;">
        <el-button plain type="danger" @click="closeForm">
          {{ $t('permission.cancel') }}
        </el-button>
        <el-button type="primary" :loading="messageCreating" @click="general ? sendGeneralPushNotification() : createMessage()">
          {{ messageCreating ? $t('general.envoiEncours') : $t('general.envoyer') }}
        </el-button>
      </div>
    </span>
    <!-- ======= USER MESSAGE DIALOG ======= -->
  </div>
</template>

<script>
import Resource from '@/api/resource';
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
const messageResource = new Resource('user');
const notifyUsersResource = new Resource('users/notifyall');
const discussionResource = new Resource('discussions/create');

export default {
  name: 'SendMessages',
  directives: { permission, role },
  props: {
    id: {
      type: Number,
      default: 0,
    },
    canals: {
      type: Array,
      default() {
        return ['Mail'];
      },
    },
    general: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      messageCreating: false,
      editing: false,
      currentMessage: {},
      dialogCreateMessage: false,
      rules: {
        subject: [{ required: true, message: this.$t('message.subjectRequired'), trigger: 'blur' }],
        body: [{ required: true, message: this.$t('message.messageRequired'), trigger: 'blur' }],
      },
    };
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    closeForm() {
      this.resetNewMessage();
      this.$nextTick(() => {
        this.$refs['messageForm'].clearValidate();
      });
      this.$emit('close', true);
    },

    handleCreateMessage() {
      this.editing = false;
      this.resetNewMessage();
      // this.open = true;
      this.$nextTick(() => {
        this.$refs['messageForm'].clearValidate();
      });
    },

    sendGeneralPushNotification(){
      this.$refs['messageForm'].validate((valid) => {
        if (valid) {
          this.currentMessage.canals = this.canals.join(',').toString();
          this.messageCreating = true;
          notifyUsersResource.store(this.currentMessage).then(async(response) => {
            this.$message({
              message: this.$t('message.sentMessage'),
              type: 'success',
              duration: 5 * 1000,
            });
            this.closeForm();
          })
            .finally(() => {
              this.messageCreating = false;
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          this.$message({
            type: 'danger',
            message: this.$t('general.invalidForm'),
          });
          return false;
        }
      });
    },

    createMessage() {
      this.$refs['messageForm'].validate((valid) => {
        if (valid) {
          this.currentMessage.canals = this.canals.join(',').toString();
          this.messageCreating = true;
          if (this.currentMessage.canals.includes('Internal Message')){
            discussionResource.substore('/' + this.id, this.currentMessage).then(async(response) => {
              this.$message({
                message: this.$t('message.sentMessage'),
                type: 'success',
                duration: 5 * 1000,
              });
            })
              .finally(() => {
                this.messageCreating = false;
              })
              .catch(error => {
                console.log(error);
              });
          }
          if (this.currentMessage.canals.includes('Mail') || this.currentMessage.canals.includes('Push Notification')){
            messageResource
              .substore('/' + this.id + '/message/send', this.currentMessage)
              .then(async(response) => {
                this.$message({
                  message: this.$t('message.sentMessage'),
                  type: 'success',
                  duration: 5 * 1000,
                });
                console.log(response.data);
                this.resetNewMessage();
                this.closeForm();
              })
              .catch(error => {
                console.log(error);
              })
              .finally(() => {
                this.messageCreating = false;
              });
          } else {
            this.closeForm();
          }
        } else {
          this.$message({
            type: 'danger',
            message: this.$t('general.invalidForm'),
          });
          return false;
        }
      });
    },

    resetNewMessage() {
      this.currentMessage = {
        subject: '',
        body: '',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
